<template>
    <div>
        <ts-page-title
            :title="$t('leaveRecord.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('leaveRecord.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button
                    type="info"
                    ghost
                    @click="$router.push({ name: 'leave-record-create' })"
                    >{{ $t("addNew") }}
                </Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('leaveRecord.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                    <ts-button
                        v-tooltip="$t('filter')"
                        @click.prevent="visible = true"
                    >
                        <i class="fa fa-filter"></i>
                    </ts-button>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                highlight-row
                stripe
            >
                <template slot-scope="{ row }" slot="branch">
                    {{
                        row.employee
                            ? row.employee.branch
                                ? row.employee.branch.branch_name_en
                                : ""
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="photo">
                    <img
                        v-if="row.employee"
                        class="tw-rounded tw-h-8"
                        v-lazy="{
                            src: photo(row),
                            error:
                                row.employee.sex == 'Male'
                                    ? require('@/assets/staff-default-man.png')
                                    : require('@/assets/staff-default-women.png'),
                            loading: require('@/assets/Rolling-1s-200px.svg'),
                            preLoad: 1.3,
                            attempt: 1
                        }"
                    />
                </template>
                <template slot-scope="{ row }" slot="employee_name">
                    <span class="ts-font-bold ts-whitespace-no-wrap text-blue"
                        ><i class="fas fa-user-tie"></i>
                        {{
                            row.employee
                                ? row.employee.employee_name_en +
                                  (row.employee.employee_name_kh
                                      ? " | " + row.employee.employee_name_kh
                                      : "")
                                : ""
                        }}<br
                    /></span>
                    <span
                        ><i class="fas fa-id-card"></i>
                        {{ row.employee ? row.employee.card_id : "" }}
                    </span>
                    &nbsp;
                    <span v-if="row.employee.phone"
                        ><i class="fas fa-phone"></i>
                        {{ row.employee ? row.employee.phone : "" }}
                    </span>
                </template>
                <template slot-scope="{ row }" slot="position">
                    {{
                        row.employee
                            ? row.employee.logical_position
                                ? row.employee.logical_position.position_name_en
                                : ""
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="leave_type">
                    {{ row.leave_type ? row.leave_type.leave_type_en : "" }}
                </template>
                <template slot-scope="{ row }" slot="leave_sub_item">
                    {{
                        row.leave_type_sub_item
                            ? row.leave_type_sub_item.leave_sub_item
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="date">
                    {{ row.date_from_display + " => " + row.date_to_display }}
                    <br />
                    {{ row.time_from_display + " => " + row.time_to_display }}
                </template>
                <template slot-scope="{ row }" slot="total_day">
                    <span class="text-warning tw-font-bold">{{
                        row.total_day == 1
                            ? row.total_day + " day"
                            : row.total_day + " days"
                    }}</span>
                </template>
                <template slot-scope="{ row }" slot="input_source">
                    <span class="text-info tw-font-bold">{{
                        row.input_source == "W" ? "Web" : "Mobile"
                    }}</span>
                </template>
                <template slot-scope="{ row }" slot="approved_by">
                    {{
                        row.approved_by
                            ? row.approved_by.employee
                                ? row.approved_by.employee.employee_name_en
                                : row.approved_by.user_name
                            : ""
                    }}
                </template>
                <template
                    slot-scope="{ row }"
                    slot="approve_status"
                    :style="rowStyle(row.approve_status.approve_status_id)"
                >
                    <span
                        :class="
                            statusStyle(row.approve_status.approve_status_id)
                        "
                        v-if="
                            row.approval_user.length == 0 ||
                                row.approve_status.approve_status_id == 2 ||
                                row.approve_status.approve_status_id == 3 ||
                                row.approve_status.approve_status_id == 4
                        "
                    >
                        {{
                            status(row.approve_status.approve_status_id) +
                                (row.approve_status.approve_status_id == 0 ||
                                row.approve_status.approve_status_id == 1
                                    ? row.current_approve_level
                                        ? " Lv:" + row.current_approve_level
                                        : ""
                                    : "")
                        }}
                    </span>
                    <Poptip
                        trigger="hover"
                        title="Approval user"
                        theme="light"
                        class=" tw-text-left"
                        transfer
                        v-else
                    >
                        <template #content>
                            <ul
                                v-for="(level, index) in approvalLevel(
                                    row.approval_user
                                )"
                                :key="index"
                            >
                                <b
                                    class="tw-grid ts-grid-cols-2  tw-text-left"
                                    >{{ "Level: " + index }}</b
                                >
                                <div
                                    class="tw-grid ts-grid-cols-2 tw-text-left"
                                >
                                    <li
                                        v-for="(users, index) in level"
                                        :key="index"
                                        class="tw-px-2 tw-whitespace-no-wrap"
                                    >
                                        - Name:
                                        {{ users.employee.employee_name_en }}
                                    </li>
                                </div>
                            </ul>
                        </template>
                        <span
                            :class="
                                statusStyle(
                                    row.approve_status.approve_status_id
                                )
                            "
                            >{{
                                status(row.approve_status.approve_status_id) +
                                    (row.approve_status.approve_status_id ==
                                        0 ||
                                    row.approve_status.approve_status_id == 1
                                        ? row.current_approve_level
                                            ? " Lv:" + row.current_approve_level
                                            : ""
                                        : "")
                            }}</span
                        >
                    </Poptip>
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        v-if="
                            row.approve_status.approve_status_id == 0 &&
                                row.approval_user.some(
                                    user => user.app_user_id !== user_id
                                ) &&
                                row.approval_user.some(
                                    user =>
                                        user.app_level ===
                                        row.current_approve_level
                                )
                        "
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <a
                        href="#"
                        class="text-warning ml-2"
                        @click.prevent="onApprovalModal(row, 3)"
                        v-tooltip="$t('leaveRecord.reject')"
                        :disabled="rejecting"
                        v-if="
                            (row.approve_status.approve_status_id == 0 ||
                                row.approve_status.approve_status_id == 1) &&
                                row.approval_user.length > 0 &&
                                row.approval_user.some(
                                    user => user.app_user_id === user_id
                                ) &&
                                row.approval_user.some(
                                    user =>
                                        user.app_level ===
                                        row.current_approve_level
                                )
                        "
                    >
                        <i
                            class="fas fa-circle-notch fa-spin"
                            v-if="row._deleting"
                        ></i>
                        <Icon type="ios-close-circle" size="20" v-else />
                    </a>
                    <a
                        href="#"
                        class="text-info ml-2"
                        @click.prevent="onApprovalModal(row, 2)"
                        v-tooltip="$t('leaveRecord.approve')"
                        :disabled="rejecting"
                        v-if="
                            (row.approve_status.approve_status_id == 0 ||
                                row.approve_status.approve_status_id == 1) &&
                                row.approval_user.length > 0 &&
                                row.approval_user.some(
                                    user => user.app_user_id === user_id
                                ) &&
                                row.approval_user.some(
                                    user =>
                                        user.app_level ===
                                        row.current_approve_level
                                )
                        "
                    >
                        <i
                            class="fas fa-circle-notch fa-spin"
                            v-if="row._switching"
                        ></i>
                        <Icon v-else type="ios-checkmark-circle" size="20" />
                    </a>
                    <Poptip
                        v-if="
                            row.approve_status.approve_status_id == 0 &&
                                row.approval_user.some(
                                    user => user.app_user_id !== user_id
                                ) &&
                                row.approval_user.some(
                                    user =>
                                        user.app_level ===
                                        row.current_approve_level
                                )
                        "
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="onDelete(row)"
                        :transfer="true"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
        </ts-panel>
        <Drawer
            :title="$t('filter')"
            :closable="false"
            :value="visible"
            @on-close="() => (visible = false)"
            width="300px"
        >
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t("branchName")
                }}</label>
                <ts-branch-filter @filter="value => (branch_id = value)" />
            </div>
        </Drawer>
        <Modal
            v-model="rejectModal"
            width="300"
            @on-cancel="onApprovalCancel"
            :loading="rejecting"
        >
            <p slot="header" style="color:#f60;text-align:center">
                <Icon type="ios-information-circle"></Icon>
                <span>{{ model.approve_status_id == 2 ? $t("leaveRecord.areYouSureApprove") : $t("leaveRecord.areYouSureReject") }}</span>
            </p>
            <div>
                <label :class="{ 'required': model.approve_status_id == 3 }">{{ $t("leaveRecord.reason") }}</label>
                <textarea
                    v-model.trim="model.approval_comment"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('approval_comment') }"
                    rows="3"
                    ref="approval_comment"
                ></textarea>
                <div
                    class="invalid-feedback"
                    v-if="errors.has('approval_comment')"
                >
                    {{ errors.first("approval_comment") }}
                </div>
            </div>
            <div slot="footer">
                <Button
                    :type="model.approve_status_id == 2 ? 'info' : 'warning'"
                    size="large"
                    long
                    @click="onApproval"
                    :loading="rejecting"
                    >{{ $t("yes") }}</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { debounce, groupBy } from "lodash";
import { Errors } from "form-backend-validation";
export default {
    name: "leave-recordIndex",
    data() {
        return {
            loading: false,
            errors: new Errors(),
            width: "width: 300px",
            visible: false,
            rejectModal: false,
            rejecting: false,
            branch_id: [],
            model: {
                approval_comment: ""
            },
            leave_type_id: [],
            approve_status: [],
            lr_id: null
        };
    },
    computed: {
        ...mapState("humanResource/leaveRecord", ["resources", "pagination"]),
        user_id() {
            return this.$store.state.authUser.user.user_id;
        },
        search: {
            get() {
                return this.$store.state.humanResource.leaveRecord.search;
            },
            set(newValue) {
                this.$store.commit(
                    "humanResource/leaveRecord/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "humanResource/leaveRecord/RESET_CURRENT_PAGE"
                );
            }
        },
        columns() {
            return [
                {
                    title: this.$t("leaveRecord.branch"),
                    slot: "branch",
                    resizable: true,
                    fixed: "left",
                    minWidth: 120
                },
                {
                    title: this.$t("leaveRecord.photo"),
                    slot: "photo",
                    resizable: true,
                    fixed: "left",
                    minWidth: 80
                },
                {
                    title: this.$t("leaveRecord.employeeName"),
                    slot: "employee_name",
                    minWidth: 250,
                    resizable: true,
                    fixed: "left",
                    sortable: true
                },
                {
                    title: this.$t("leaveRecord.position"),
                    slot: "position",
                    minWidth: 250
                },
                {
                    title: this.$t("leaveRecord.leaveType"),
                    slot: "leave_type",
                    minWidth: 200,
                    resizable: true,
                    sortable: true,
                    filters: [
                        { value: 1, label: "Annual Leave" },
                        { value: 2, label: "Maternity Leave" },
                        { value: 3, label: "Paternity Leave" },
                        { value: 4, label: "Sick Leave" },
                        { value: 5, label: "Unpaid Leave" },
                        { value: 6, label: "Special Leave" }
                    ],
                    filterMultiple: true,
                    filterRemote(value) {
                        this.leave_type_id = value;
                    }
                },
                {
                    title: this.$t("leaveRecord.leaveSubItem"),
                    slot: "leave_sub_item",
                    resizable: true,
                    minWidth: 200
                },
                {
                    title: this.$t("leaveRecord.date"),
                    slot: "date",
                    minWidth: 200,
                    resizable: true,
                    align: "center"
                },
                {
                    title: this.$t("leaveRecord.totalDays"),
                    slot: "total_day",
                    minWidth: 200,
                    resizable: true,
                    align: "center"
                },
                {
                    title: this.$t("leaveRecord.status"),
                    slot: "approve_status",
                    minWidth: 120,
                    resizable: true,
                    fixed: "right",
                    align: "center",
                    filters: [
                        {
                            label: "Pending",
                            value: 0
                        },
                        {
                            label: "Partially Approved",
                            value: 1
                        },
                        {
                            label: "Approved",
                            value: 2
                        },
                        {
                            label: "Rejected",
                            value: 3
                        }
                    ],
                    filterMultiple: true,
                    filterRemote(value) {
                        this.approve_status = value;
                    }
                },
                {
                    title: this.$t("leaveRecord.reason"),
                    key: "leave_reason",
                    minWidth: 500,
                    resizable: true
                },
                {
                    title: this.$t("leaveRecord.inputSource"),
                    slot: "input_source",
                    minWidth: 100,
                    resizable: true
                },
                {
                    title: this.$t("leaveRecord.approvedBy"),
                    slot: "approved_by",
                    minWidth: 200,
                    resizable: true
                },
                {
                    title: this.$t("leaveRecord.approvalComment"),
                    key: "approved_comment",
                    minWidth: 400,
                    resizable: true
                },
                {
                    title: this.$t("actions"),
                    key: "action",
                    slot: "action",
                    fixed: "right",
                    align: "center",
                    resizable: true,
                    width: 120
                }
            ];
        },
        rowStyle: () => status => {
            let color = "";

            switch (status) {
                case 1:
                    color = "";
                    break;
                case 2:
                    color = "";
                    break;
                case 3:
                    color = "#90949c";
                    break;
            }

            return {
                color: color
            };
        },
        status: () => status => {
            let value = "";

            switch (status) {
                case 0:
                    value = "Pending";
                    break;
                case 1:
                    value = "Partially Approved";
                    break;
                case 2:
                    value = "Approved";
                    break;
                case 3:
                    value = "Rejected";
                    break;
            }

            return value;
        },
        statusStyle: () => status => {
            switch (status) {
                case 1:
                    return "badge bg-info rounded-pill";
                case 2:
                    return "badge bg-primary rounded-pill";
                case 3:
                    return "badge bg-danger rounded-pill";
                default:
                    return "badge bg-info rounded-pill";
            }
        }
    },
    methods: {
        approvalLevel(approval_user) {
            let approvalByLevel = groupBy(approval_user, "app_level");
            return approvalByLevel;
        },
        photo(record) {
            if (record.photo) {
                return record.photo;
            }

            return record.sex == "female"
                ? require("@/assets/staff-default-women.png")
                : require("@/assets/staff-default-man.png");
        },
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("humanResource/leaveRecord/fetch", {
                    filter: {
                        leave_type_id: this.leave_type_id,
                        approve_status: this.approve_status,
                        search: this.search
                    },
                    branch_id: this.branch_id,
                    ...attributes
                })
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onEdit(record) {
            this.$store.commit(
                "humanResource/leaveRecord/SET_EDIT_DATA",
                record
            );
            this.$router.push({
                name: "leave-record-edit",
                params: { id: record.lr_id }
            });
        },

        async onDelete(record) {
            this.$v.form.$touch();
            if (this.$v.form.$invalid) return;

            record._deleting = true;
            this.$store
                .dispatch("humanResource/leaveRecord/destroy", {
                    id: record.lr_id,
                    data: this.model
                })
                .then(response => {
                    this.fetchData();
                    record._deleting = false;
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    record._deleting = false;
                });
        },
        onApprovalModal(record, approve_status_id) {
            this.model.lr_id = record.lr_id;
            this.model.approve_status_id = approve_status_id;
            this.model.approval_comment = "";
            this.rejectModal = true;
            this.errors = new Errors();
            this.$nextTick(() => {
                this.$refs.approval_comment.focus();
            });
        },
        onApproval() {
            this.errors = new Errors();
            this.rejecting = true;
            this.$store
                .dispatch("humanResource/leaveRecord/approveOrReject", {
                    id: this.model.lr_id,
                    data: this.model
                })
                .then(response => {
                    this.rejecting = false;
                    if (response.message_id == 0) {
                        this.notice({
                            type: "success",
                            text: response.message
                        });
                        this.onApprovalCancel()
                        this.fetchData();
                    }
                    if (response.message_id > 0) {
                        this.notice({
                            type: "warning",
                            text: response.message
                        });
                    }
                })
                .catch(error => {
                    this.rejecting = false;
                    this.errors = new Errors(error.errors);
                    this.notice({
                        type: "error",
                        text: error.message
                    });
                });
        },
        onApprovalCancel() {
            this.model.lr_id = null;
            this.model.approve_status_id = null;
            this.model.approval_comment = "";
            this.rejectModal = false;
            this.errors = new Errors();
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LEAVE RECORD",
                desc: not.text
            });
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500),
        branch_id: debounce(function() {
            this.fetchData();
        }, 500),
        leave_type_id: debounce(function() {
            this.fetchData();
        }, 500),
        approve_status: debounce(function() {
            this.fetchData();
        }, 500)
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("humanResource/leaveRecord/RESET_STATE");
        next();
    }
};
</script>
